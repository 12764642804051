
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      's1b9047d53d9f9d22': `2) Wybierz "Udostępnij"`,
's37a9e8aec5713460': `Więcej`,
's5c6aad7a7e4a1437': `3) Wybierz "Dodaj do Ekranu Głównego"`,
's6196153c4b0c1ea0': `Zainstaluj`,
's8d0173763d91830c': `Ta strona posiada aplikację. Dodaj ją do Ekranu Głównego, aby zyskać pełniejsze doświadczenie i łatwiejszy dostęp.`,
'sa5ef80b4bb9b39f8': `Mniej`,
'sc79fd7641eb9a975': `Ukryj instrukcję`,
'scdaf4bbff76674c8': `Dodaj do Ekranu Głównego`,
'sdca23702207fba73': `Ta strona posiada aplikację. Zainstaluj ją, aby zyskać pełniejsze doświadczenie i łatwiejszy dostęp.`,
'sdfe23506a3b0cdc8': `1) Otwórz przeglądarkę Safari`,
    };
  