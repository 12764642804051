
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      's1b9047d53d9f9d22': `2) Πατήστε Κοινοποίηση στη γραμμή πλοήγησης`,
's37a9e8aec5713460': `Περισσότερα`,
's5c6aad7a7e4a1437': `3) Πατήστε Προσθήκη στην οθόνη Αφετηρίας`,
's6196153c4b0c1ea0': `Εγκατάσταση`,
's8d0173763d91830c': `Αυτός ο ιστότοπος έχει λειτουργικότητα εφαρμογής. Προσθέστε την στην οθόνη αφετηρίας για εκτεταμένη εμπειρία και εύκολη πρόσβαση.`,
'sa5ef80b4bb9b39f8': `Λιγότερα`,
'sc79fd7641eb9a975': `Απόκρυψη Οδηγιών`,
'scdaf4bbff76674c8': `Προσθήκη στην Οθόνη Αφετηρίας`,
'sdca23702207fba73': `Αυτός ο ιστότοπος έχει λειτουργικότητα εφαρμογής. Εγκαταστήστε την στη συσκευή σας για εκτεταμένη εμπειρία και εύκολη πρόσβαση.`,
'sdfe23506a3b0cdc8': `1) Ανοίξτε τον περιηγητή Safari`,
    };
  