
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      's1b9047d53d9f9d22': `2) Appuyez sur Partager dans la barre de navigation`,
's37a9e8aec5713460': `Plus`,
's5c6aad7a7e4a1437': `3) Appuyez sur Ajouter à l'écran d'accueil`,
's6196153c4b0c1ea0': `Installer`,
's8d0173763d91830c': `Ce site dispose d'une fonctionnalité d'application. Ajoutez-le à votre écran d'accueil
        pour une expérience étendue et un accès facile.`,
'sa5ef80b4bb9b39f8': `Moins`,
'sc79fd7641eb9a975': `Masquer les instructions`,
'scdaf4bbff76674c8': `Ajouter à l'écran d'accueil`,
'sdca23702207fba73': `Ce site dispose d'une fonctionnalité d'application. Installez-la sur votre appareil
        pour une expérience étendue et un accès facile.`,
'sdfe23506a3b0cdc8': `1) Ouvrir dans le navigateur Safari`,
    };
  