
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      's1b9047d53d9f9d22': `2) Притисните Share у навигационој траци`,
's37a9e8aec5713460': `Више`,
's5c6aad7a7e4a1437': `3) Притисните Add to Home Screen`,
's6196153c4b0c1ea0': `Инсталирај`,
's8d0173763d91830c': `Овај сајт има функционалност апликације. Додајте је на почетни екран за обогаћено искуство и једноставан приступ.`,
'sa5ef80b4bb9b39f8': `Мање`,
'sc79fd7641eb9a975': `Сакриј упутство`,
'sdca23702207fba73': `Овај сајт има функционалност апликације. Инсталирајте је на свој уређај за обогаћено искуство и једноставан приступ.`,
'sdfe23506a3b0cdc8': `1) Отворите у Safari прегледачу`,
'scdaf4bbff76674c8': `Add to Home Screen`,
    };
  