
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      's1b9047d53d9f9d22': `2) Presionar Compartir en la barra de direcciones`,
's37a9e8aec5713460': `Más`,
's5c6aad7a7e4a1437': `3) Presionar Añadir a pantalla de inicio`,
's6196153c4b0c1ea0': `Instalar`,
's8d0173763d91830c': `Este sitio tiene una funcionalidad de aplicación. Es posible agregarla a la pantalla de inicio para una amplia experiencia y acceso fácil.`,
'sa5ef80b4bb9b39f8': `Menos`,
'sc79fd7641eb9a975': `Esconder instrucciones`,
'scdaf4bbff76674c8': `Añadir a pantalla de inicio`,
'sdca23702207fba73': `Este sitio tiene una funcionalidad de aplicación. Es posible instalarla en este dispositivo para una amplia experiencia y acceso fácil.`,
'sdfe23506a3b0cdc8': `1) Abrir en el navegador web Safari`,
    };
  